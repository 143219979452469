import { createLogic } from "redux-logic";
import {
  API_SESSION_FETCH,
  API_SESSION_SUCCESS,
  API_SESSION_ERROR,
  API_SESSION_INVALID,
  API_SESSION_NOCODE,
  API_SOLVE_PUZZLE,
  API_SOLVE_PUZZLE_ERROR,
  API_SOLVE_PUZZLE_SUCCESS,
  API_LOG_OUT,
  API_LOG_ANSWER,
  API_EXECUTE_MUTATION,
  API_EXECUTE_MUTATION_SUCCESS,
} from "./apiActionTypes";
import { GET_SESSION, LOG_ANSWER, SET_PUZZLE } from "./queries";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import axios from "axios";

const link: ApolloLink = createHttpLink({
  //uri: process.env.NODE_ENV === 'production' ? 'http://localhost:4000/' : 'http://localhost:4000/',
  uri: "https://pubcrawl-backend.herokuapp.com/v1/graphql",
  headers: {
    "x-hasura-admin-secret": "PuFRNx}e%g2}vFpn",
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const fetchSession = createLogic({
  type: API_SESSION_FETCH, // only apply this logic to this type
  latest: true, // only take latest
  async process({ getState, action }, dispatch, done) {
    let voucherCode = localStorage.getItem("voucherCode");
    const myAction = action as any;
    if (!myAction.payload && !voucherCode) {
      dispatch({
        type: API_SESSION_NOCODE,
      });
      console.log("No voucherCode detected");
      done();
      return;
    }

    try {
      const response = await axios.get(`https://detectivetour.hu/game/couponCodeIsValid/${myAction.payload.voucherCode ?? voucherCode}`);
      dispatch({
        type: API_SESSION_SUCCESS,
        payload: response.data,
      });
      done();
    } catch (error) {
      dispatch({
        type: API_SESSION_INVALID,
      });
    }
  },
});

const solvePuzzle = createLogic({
  type: API_SOLVE_PUZZLE, // only apply this logic to this type
  latest: true, // only take latest
  async process({ getState, action }, dispatch, done) {
    let voucherCode = localStorage.getItem("voucherCode");

    if (!voucherCode) {
      dispatch({
        type: API_SOLVE_PUZZLE_ERROR,
      });
      console.log("No voucher code detected");
      done();
      return;
    }

    const state = getState() as any;

    await client
      .mutate({
        mutation: SET_PUZZLE,
        variables: {
          voucherCode: voucherCode,
          puzzleId: state.apiReducer.session.puzzleId + 1,
        },
      })
      .then((result) => {
        console.log(result);

        dispatch({
          type: API_SOLVE_PUZZLE_SUCCESS,
          payload: result.data.update_Sessions.returning[0],
        });
        /*
            if (result.data.Sessions.length !== 0) {
                dispatch({
                    type: API_SESSION_SUCCESS,
                    payload: result.data.Sessions[0]
                })
                console.log(`Valid voucherCode with session: ${JSON.stringify(result.data.Sessions[0])}`)
            } else {
                dispatch({
                    type: API_SESSION_INVALID,
                })
                console.log('Invalid voucherCode')
            }
            */
      })
      .catch((error) => {
        dispatch({
          type: API_SOLVE_PUZZLE_ERROR,
        });
        console.log(`Error solving puzzle: ${error}`);
      })
      .finally(() => {
        done();
      });
  },
});

const logAnswer = createLogic({
  type: API_LOG_ANSWER, // only apply this logic to this type
  latest: true, // only take latest
  async process({ getState, action }, dispatch, done) {
    const state = getState() as any;
    const answer = (action as any).payload.answer;

    await client
      .mutate({
        mutation: LOG_ANSWER,
        variables: {
          sessionId: state.apiReducer.session.id,
          puzzleId: state.apiReducer.session.puzzleId,
          answer: answer,
        },
      })
      .then((result) => {
        console.log("Success logging answer");
      })
      .catch((error) => {
        console.log(`Error logging answer: ${error}`);
      })
      .finally(() => {
        done();
      });
  },
});

const executeMutation = createLogic({
  type: API_EXECUTE_MUTATION, // only apply this logic to this type
  latest: false, // only take latest
  async process({ action, getState }, dispatch, done) {
    const payload = (action as any).payload;
    const sessionId = (getState() as any).apiReducer.session.id;
    
    try {
      const response = await axios.post(`https://detectivetour.hu/game/saveGameState/${sessionId}`, payload);
      dispatch({
        type: API_EXECUTE_MUTATION_SUCCESS,
        payload: response.data,
      });
      done();
    } catch (error) {
      dispatch({
        type: API_SESSION_INVALID,
      });
    }
    return;

    await client
      .mutate({
        ...payload,
        variables: {
          ...payload.variables,
          sessionId: sessionId,
        },
      })
      .then((result) => {
        console.log(`Success executing mutation: \n${JSON.stringify(payload)}`);

        const returnedData =
          result.data[Object.keys(result.data)[0]].returning[0];

        dispatch({
          type: API_EXECUTE_MUTATION_SUCCESS,
          payload: returnedData,
        });
      })
      .catch((error) => {
        console.log(
          `Error executing mutation: \n${JSON.stringify(
            payload
          )}\nError:${error}`
        );
      })
      .finally(() => {
        done();
      });
  },
});

const logOut = createLogic({
  type: API_LOG_OUT, // only apply this logic to this type
  latest: true, // only take latest
  async process({ getState, action }, dispatch, done) {
    localStorage.removeItem("voucherCode");
    done();
  },
});

// pollsLogic
export default [fetchSession, solvePuzzle, logAnswer, logOut, executeMutation];
