import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/api/apiActions";
import { I18nService } from "../../services/i18n-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      padding: 10
    },
    title: {
      alignSelf: "center"
    },
    label: {
      display: "flex"
    },
    textField: {},
    sendButton: {
      display: "flex"
    },
    alertComponent: {}
  })
);

type StartPuzzleProp = {
  onComplete: Function;
};

const StartPuzzle: React.FC<StartPuzzleProp> = ({ onComplete }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [aszf, setAszf] = useState(true);

  const onSend = () => {
    /*dispatch(
      setUserData({
        name: name,
        email: email,
        phoneNumber: phoneNumber
      })
    );*/
    onComplete();
  };

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
        color="primary"
        component="h2"
      >
        {new I18nService().getTranslationFor('data')}
      </Typography>
      


      <Box className={classes.label}>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('name')}
        </Typography>
      </Box>
      <TextField
        id="outlined-number"
        label={new I18nService().getTranslationFor('name')}
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
      />
      <Box className={classes.label}>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('emailAddress')}
        </Typography>
      </Box>
      <TextField
        id="outlined-number"
        label={new I18nService().getTranslationFor('emailAddressShort')}
        value={email}
        onChange={e => {
          setEmail(e.target.value);
        }}
        type="email"
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
      />
      
      
      
      <Box className={classes.label}>
        <Typography variant="body1" color="textSecondary" component="p">
          {new I18nService().getTranslationFor('phoneNumber')}
        </Typography>
      </Box>
      <TextField
        id="outlined-number"
        label={new I18nService().getTranslationFor('phoneNumber')}
        value={phoneNumber}
        onChange={e => {
          setPhoneNumber(e.target.value);
        }}
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
      />



      <FormControlLabel
        value="end"
        control={
          <Checkbox
            color="primary"
            checked={aszf}
            onChange={() => {
              setAszf(!aszf);
            }}
          />
        }
        label={
          <div>
            {new I18nService().getTranslationFor('acceptTermsAndConditions')}
            <Link href={new I18nService().getTranslationFor('termsAndConditionsUrl')}>
            {new I18nService().getTranslationFor('termsAndConditions')}
            </Link>
          </div>
        }
        labelPlacement="end"
      />
      <Button
        className={classes.sendButton}
        size="large"
        variant="contained"
        color="primary"
        onClick={() => {
          onSend();
        }}
        disabled={!aszf || email === ""}
      >
        {new I18nService().getTranslationFor('continueButton')}
      </Button>
    </Box>
  );
};

export default StartPuzzle;
